import {webClient} from "./axios";
import {handleError} from "./handleRequestError";

export let organizationRequest = (id,callback) => {
    webClient.get(`/map/${id}`)
        .then(response => {
            callback(false,response.data);
        })
        .catch(err => {
            handleError(err, callback);
        })
};


export const sensorDataRequest = (mapId, stationId, selectedPollutant, period, callback) => {
    let url = `/data/${mapId}/${stationId}?variable=${selectedPollutant}&range=${period}`;
    webClient.get(url)
        .then(response => {
            callback(false,response.data);
        })
        .catch(err => {
            handleError(err, callback);
        })
};