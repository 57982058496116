import {getVariableName} from "../../../utils/pollutantNames";
import {unitsMap} from "../../../utils/unitsNames";

export const getLayout = (plotLayout, threshold, data, selectedPollutant, units, thresholdTextLabel, translatedNoise) => {
    return threshold === null ?
        {
            ...plotLayout, yaxis: {
                title: `${selectedPollutant === "VOC_IAQ" ? "IAQ" : `${selectedPollutant === "noise" ? translatedNoise : getVariableName(selectedPollutant)} - ${selectedPollutant === "noise" ? "dB" : ((selectedPollutant === "pressure") || (selectedPollutant ===  "equivalentPressure")) ? "hPa" : unitsMap.get(units)}`}`
            }
        } :
        {
            ...plotLayout, yaxis: {
                title: `${selectedPollutant === "VOC_IAQ" ? "IAQ" : `${getVariableName(selectedPollutant)} - ${selectedPollutant === "noise" ? "dB" : ((selectedPollutant === "pressure") || (selectedPollutant ===  "equivalentPressure")) ? "hPa" : unitsMap.get(units)}`}`
            }
            ,
            shapes: [
                {
                    type: 'line',
                    xref: 'x',
                    x0: data[0].x[0],
                    y0: threshold,
                    x1: data[0].x[(data[0].x.length - 1)],
                    y1: threshold,
                    line: {
                        color: 'rgb(255, 0, 0)',
                        width: 2.5,
                    }
                }
            ],
            annotations: [
                {
                    x: data[0].x[(Math.round(data[0].x.length / 2))],
                    y: threshold,
                    xref: 'x',
                    yref: "y1",
                    text: `${thresholdTextLabel} ${threshold} ${data[0].unit}`,
                    showarrow: true,
                    arrowhead: 10,
                    ax: 0,
                    ay: -20
                }
            ]
        }
}
