import React from 'react';
import PollutantToggleButtons from "./PollutantToggleButtons";
import {DESKTOP_DETAIL_HEIGHT} from "../../MapView";
import Plot from "react-plotly.js";
import './chart_style.css';
import {getFilterSensors} from "../../../utils/stationUtil";
import {useTranslation} from "react-i18next";
import ShareIcon from '@mui/icons-material/Share';
import {useSnackbar} from "notistack";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import {SHARE_URL_DOMAIN} from "../StationDetail";
import {dateToReadableDateTimeString} from "../../../utils/dateToReadableDateTimeString";
import PeriodSelector from "../PeriodSelector";
import {getLayout} from "../util/chartUtil";
import {Button, CircularProgress, Fade, Grid, Typography} from "@mui/material";
import {airQualityIndexColors, CATEGORICAL_INDEXES, indexMoreInfoUrl} from "../../../constants";
import {getAQILegend} from "bettairplaformutil/src/indexUtil";
import {getIndexRange, getValueColorPosition} from "../../../utils/airQualityIndexUtil";
import DisclaimerButton from "../../station_pop_up/DisclaimerButton";


const DesktopDetail = ({
                           station, lastUpdate, indexType, loading, data, units,
                           pollutantCallback, selectedPollutant, period, periodCallback, threshold,path
                       }) => {
    const {t, i18n} = useTranslation()
    const {enqueueSnackbar} = useSnackbar();
    const plotConfig = {
        modeBarButtonsToRemove: ["select2d", "lasso2d",
            "toggleHover", "sendDataToCloud", "toggleSpikelines", "hoverCompareCartesian",
            "hoverClosestCartesian"
        ],
        displaylogo: false
    };

    const plotLayout = {
        showlegend: false,
        margin: {
            l: 50,
            r: 50,
            b: 80,
            t: 8,
            pad: 8
        },
        autosize: true,
        legend: {x: -0.22, y: 0.9},
        yaxis: {
            title: 'dB'
        },
        xaxis: {showgrid: false, domain: [0.01, 1]}
    };

    const rangeInfo = getAQILegend(indexType, i18n.language);
    const rangeValues = getIndexRange(indexType);
    const colorPosition = getValueColorPosition(station.index, rangeValues);
    const backgroundColor = airQualityIndexColors[indexType][colorPosition];

    return (
        <div style={{
            height: DESKTOP_DETAIL_HEIGHT, display: "flex", flexDirection: "column",
            alignItems: "center", justifyContent: "center"
        }}>
            <Grid container direction={"row"} style={{width: 1200, margin: 0, height: "100%", paddingTop: 20}}>
                <Grid item xs={6}>
                    <Fade in={true} timeout={900}>
                        <Typography variant={"h5"}>{station.alias}</Typography>
                    </Fade>
                </Grid>
                <Grid item xs={6} container style={{display: "flex", paddingRight: 30, justifyContent: "end"}}>
                    <DisclaimerButton style={{marginRight: 12}}/>
                    <CopyToClipboard text={`${SHARE_URL_DOMAIN}/${path}?id=${station.id}`}
                                     onCopy={() => enqueueSnackbar(`${t("detail.copied")}`, {variant: "info"})}>
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<ShareIcon/>}
                        >
                            {t("detail.share")}
                        </Button>
                    </CopyToClipboard>
                </Grid>

                <Grid item xs={3}>
                    <Fade in={true} timeout={900}>
                        <Typography
                            style={{color: "#808080"}}>{`${"Last Update"} ${dateToReadableDateTimeString(new Date(lastUpdate))}`}</Typography>
                    </Fade>
                </Grid>
                <Grid item xs={9}/>

                <Grid item xs={12} style={{marginBottom: 20}}/>

                <Grid item xs={3}/>
                <Grid item xs={7} container style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <PollutantToggleButtons pollutantList={getFilterSensors(station) || []}
                                            pollutantCallback={pollutantCallback}
                                            selectedPollutant={selectedPollutant}/>
                </Grid>
                <Grid item xs={2}>
                    <PeriodSelector loading={loading} period={period} handleSelectorChange={periodCallback}/>
                </Grid>

                <Grid item xs={12} style={{marginBottom: 20}}/>

                <Grid item xs={3} style={{paddingTop: 20}}>
                    <Fade in={true} timeout={1200}>
                        <div style={{
                            height: "100%",
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }}>
                            <Typography style={{
                                background: backgroundColor,
                                width: "100%",
                                padding: 4,
                                fontWeight: "bold"
                            }}>
                                {indexType}{": "}
                                {station.index === undefined ? "N/A" :
                                    CATEGORICAL_INDEXES.includes(indexType) ? rangeInfo[station.index] :
                                        `${Math.round(station.index)} - ${rangeInfo[getValueColorPosition(station.index, rangeValues)]}`}
                            </Typography>
                            <Typography>{t(`detail.learnMore${indexType}`)}</Typography>
                            <a
                                href={indexMoreInfoUrl[indexType]}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    cursor: "pointer",
                                    color: "#135fb4",
                                    textDecoration: "underline",
                                    textAlign: "start",
                                    width: "100%"
                                }}>{indexMoreInfoUrl[indexType].split("/")[2]}</a>
                        </div>
                    </Fade>
                </Grid>
                <Fade in={true} timeout={900}>
                    <Grid item xs={9} container
                          style={{display: "flex", alignItems: "center", justifyContent: "center", height: 280}}
                          className={"dataChartDesktop"}>
                        {!loading && data !== null && data?.length !== 0 && <Plot
                            useResizeHandler={true}
                            layout={getLayout(plotLayout, threshold, data, selectedPollutant, units, t("detail.recommendedValue"),t("ambient_noise"))}
                            data={data}
                            onInitialized={(figure) => this.setState(figure)}
                            onUpdate={
                                (figure) =>
                                    this.setState(figure)
                            }
                            config={plotConfig}>
                        </Plot>}
                        {!loading && data === null && <div style={{
                            display: "flex", alignItems: "center",
                            justifyContent: "center", height: 280
                        }}>
                            <Typography style={{fontSize: 18}} variant={"subtitle1"}>{t("no_data")}</Typography>
                        </div>
                        }
                        {loading && <div style={{
                            display: "flex", flexDirection: "column",
                            alignItems: "center", justifyContent: "center", height: "100%"
                        }}>
                            <CircularProgress/>
                        </div>}
                    </Grid>
                </Fade>
            </Grid>
        </div>
    );
};

export default DesktopDetail;
