import React from 'react';
import {contrastColor} from "contrast-color";
import {Grid, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {TEXT_COLOR, TEXT_COLOR_CONTRAST} from "../colors";

const useStyles = makeStyles({
    value: {
        textAlign: "left",
        minWidth: 55,
        fontWeight: "bold",
        marginRight: 8,
        marginLeft: 8,
        paddingLeft:8,
    },
    description: {
        textAlign: "left",
        fontWeight: "bold",
        paddingLeft: 8
    },
    item: {
        marginBottom: 0
    }
});

const AirQualityIndexLegend = ({data}) => {

    const {colors, indexInfo, rangeInfo} = data;

    const classes = useStyles();

    return (<>
            {indexInfo.length > 0 ? indexInfo.map((item, index) => {
                const contrast = contrastColor({
                    bgColor: colors[index],
                    fgDarkColor: TEXT_COLOR,
                    fgLightColor: TEXT_COLOR_CONTRAST
                });
                return (
                    <Grid item xs={12} container className={classes.item} backgroundColor={colors[index]} key={index}>
                        { rangeInfo &&
                            <Typography variant={"subtitle2"} className={classes.value} color={contrast}>
                                {rangeInfo[index]}
                            </Typography>
                        }
                        <Typography variant={"subtitle2"} className={classes.description} color={contrast}>
                            {item}
                        </Typography>
                    </Grid>
                );
            }) : <Grid item xs={12}/>}
        </>
    )
        ;
};

export default AirQualityIndexLegend;
