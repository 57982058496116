
export const dateToReadableDateTimeString = (date)=>{
    return dateToTimeString(date) +
        " "+dateToReadableUTCString(date)
}

const dateToTimeString = (date) =>{
    return `${date.getHours()<10?`0${date.getHours()}`:
        `${date.getHours()}`}:${date.getMinutes()<10?`0${date.getMinutes()}`:
        `${date.getMinutes()}`}:${date.getSeconds()<10?`0${date.getSeconds()}`:
        `${date.getSeconds()}`}`
}

export const dateToReadableUTCString = (date)=>{
    return `${date.getDate()<10?`0${date.getDate()}`:
        date.getDate()}/${date.getMonth()<9?`0${date.getMonth()+1}`:
        date.getMonth()+1}/${date.getFullYear()}`
}
