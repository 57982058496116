import React from 'react';
import {useTranslation} from "react-i18next";
import {dateToReadableDateTimeString} from "../../../utils/dateToReadableDateTimeString";
import PollutantTable from "../PollutantTable";
import TimelineIcon from '@mui/icons-material/Timeline';
import {Button, Fade, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {getAQILegend} from "bettairplaformutil/src/indexUtil";
import {getIndexRange, getValueColorPosition} from "../../../utils/airQualityIndexUtil";
import {airQualityIndexColors, CATEGORICAL_INDEXES} from "../../../constants";
import DisclaimerButton from "../DisclaimerButton";

const useStyles = makeStyles({
    box: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        zIndex: 1999,
        transform: 'translate(-50%, -45%)',
        display: "flex",
        flexDirection:"column",
        alignItems:"center"
    },
    data:{
        width: 280,
        height: 300,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        borderRadius: 6,
        background: "#ffffff",
        padding: 16,
        zIndex: 1999,
        boxShadow: "rgba(0, 0, 0, 0.25) 2px 6px 4px 2px",
        alignSelf: "center"
    },
    pointer:{
        borderLeft: "40px solid transparent",
        borderRight: "40px solid transparent",
        borderBottom: "40px solid #ffffff",
        width:0,
        height:0,
        zIndex: 1999,
    }
});

const MobileStationPopUp =  React.forwardRef((props, ref) => {

    const {t, i18n} = useTranslation();
    const classes = useStyles();

    const {units, station, onHistorySelected} = props;

    const rangeInfo = getAQILegend(units.index, i18n.language);
    const rangeValues = getIndexRange(units.index);
    const colorPosition = getValueColorPosition(station.index, rangeValues);
    const backgroundColor = airQualityIndexColors[units.index][colorPosition];

    return (
        <Fade ref={ref} in={true}>
            <div ref={ref} className={classes.box}>
                <div className={classes.pointer}/>
                <div  className={classes.data}>
                    <Typography variant={"h5"}>{station.alias}</Typography>
                    <Typography
                        style={{
                            color: "#808080",
                            fontSize: 12
                        }}>{`${t("popup.lastData")} ${dateToReadableDateTimeString(new Date(station.lastData))}`}</Typography>
                    <Typography style={{
                        borderRadius: 6,
                        marginBottom: 8,
                        marginTop: 8,
                        textAlign: "center",
                        background: backgroundColor,
                        width: 242,
                        padding: 4,
                        fontWeight: "bold"
                    }}>
                        {units.index}{": "}
                        {station.index === undefined ? "N/A" :
                            CATEGORICAL_INDEXES.includes(units.index) ? rangeInfo[station.index] :
                                `${Math.round(station.index)} - ${rangeInfo[getValueColorPosition(station.index, rangeValues)]}`}
                    </Typography>
                    <PollutantTable units={units} station={station} maxHeight={170}/>
                    <Button
                        variant="outlined"
                        startIcon={<TimelineIcon/>}
                        style={{width: 250, marginTop: 8}}
                        onClick={onHistorySelected}>
                        {t("popup.stationHistory")}
                    </Button>
                    <DisclaimerButton style={{marginTop: 12}}/>
                </div>
            </div>
        </Fade>
    );
});

export default MobileStationPopUp;
