import React from 'react';
import {MOBILE_DETAIL_HEIGHT} from "../../MapView";
import Plot from "react-plotly.js";
import './chart_style.css';
import {getFilterSensors} from "../../../utils/stationUtil";
import {getVariableName} from "../../../utils/pollutantNames";
import {useTranslation} from "react-i18next";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import {SHARE_URL_DOMAIN} from "../StationDetail";
import ShareIcon from '@mui/icons-material/Share';
import {useSnackbar} from "notistack";
import PeriodSelector from "../PeriodSelector";
import {getLayout} from "../util/chartUtil";
import {CircularProgress, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import {getAQILegend} from "bettairplaformutil/src/indexUtil";
import {getIndexRange, getValueColorPosition} from "../../../utils/airQualityIndexUtil";
import {airQualityIndexColors, CATEGORICAL_INDEXES, indexMoreInfoUrl} from "../../../constants";
import DisclaimerButton from "../../station_pop_up/DisclaimerButton";


const plotConfig = {
    displayModeBar: false,
    displaylogo: false
};

const plotLayout = {
    margin: {
        l: 36,
        r: 36,
        b: 50,
        t: 16,
        pad: 0
    },
    autosize: true,
    legend: {x: -0.22, y: 0.9},
    yaxis: {
        title: 'dB'
    },
    xaxis: {showgrid: false, domain: [0.01, 1]},
};

const MobileDetail = ({
                          indexType,
                          station,
                          loading,
                          data,
                          units,
                          pollutantCallback,
                          selectedPollutant,
                          period,
                          periodCallback,
                          threshold,
                          path
                      }) => {

    const {t, i18n} = useTranslation()
    const {enqueueSnackbar} = useSnackbar();

    const rangeInfo = getAQILegend(indexType, i18n.language);
    const rangeValues = getIndexRange(indexType);
    const colorPosition = getValueColorPosition(station.index, rangeValues);
    const backgroundColor = airQualityIndexColors[indexType][colorPosition];

    const handleSelectorChange = (event) => {
        pollutantCallback(event.target.value);
    }

    return (
        <div style={{
            height: MOBILE_DETAIL_HEIGHT, display: "flex", flexDirection: "column",
            alignItems: "center", justifyContent: "center"
        }}>
            <Grid container style={{height: "100%", paddingLeft: 12, paddingRight: 12, paddingTop: 12}}>
                <Grid item xs={7}>
                    <div style={{display: "flex", alignItems: "center"}}><Typography
                        variant={"h5"}>{station.alias}</Typography>
                        <CopyToClipboard text={`${SHARE_URL_DOMAIN}/${path}?id=${station.id}`}
                                         onCopy={() => enqueueSnackbar(`${t("detail.copied")}`, {variant: "info"})}>
                            <IconButton>
                                <ShareIcon/>
                            </IconButton>
                        </CopyToClipboard>
                        <DisclaimerButton style={{marginLeft: 8}}/>
                    </div>
                    <Typography style={{fontSize: 12}}>Last update: 12/05/2021 13:32</Typography>
                </Grid>
                <Grid item xs={5} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <PeriodSelector period={period} handleSelectorChange={periodCallback} loading={loading}/>
                </Grid>
                <Grid item xs={4}>
                    <div style={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    }}>
                        <Typography style={{
                            background: backgroundColor,
                            fontSize: 14,
                            width: "100%",
                            padding: 4,
                            fontWeight: "bold",
                            marginTop: 8
                        }}>
                            {indexType}{": "}
                            {station.index === undefined ? "N/A" :
                                CATEGORICAL_INDEXES.includes(indexType) ? rangeInfo[station.index] :
                                    `${Math.round(station.index)} - ${rangeInfo[getValueColorPosition(station.index, rangeValues)]}`}
                        </Typography>
                        <Typography>{t(`detail.learnMore${indexType}`)}</Typography>
                        <a
                            href={indexMoreInfoUrl[indexType]}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                                cursor: "pointer",
                                color: "#135fb4",
                                textDecoration: "underline",
                                textAlign: "start",
                                width: "100%"
                            }}>{indexMoreInfoUrl[indexType].split("/")[2]}</a>
                    </div>
                </Grid>
                <Grid item xs={4}/>
                <Grid item xs={4}

                      container style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <FormControl style={{width:"100%"}} disabled={loading}>
                        <InputLabel>{t("detail.variable")}</InputLabel>
                        <Select
                            fullWidth
                            variant={"standard"}
                            value={selectedPollutant}
                            onChange={event => handleSelectorChange(event)}
                        >
                            {getFilterSensors(station).map(sensor => {
                                return <MenuItem key={sensor}
                                                 value={sensor}>{sensor === "noise" ? t("ambient_noise") : getVariableName(sensor)}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}
                      style={{display: "flex", alignItems: "center", justifyContent: "center", height: 220}}
                      className={"dataChart"}>
                    {!loading && data !== null && data?.length !== 0 && <Plot
                        useResizeHandler={true}
                        layout={getLayout(plotLayout, threshold, data, selectedPollutant, units, t("detail.recommendedValue"), t("ambient_noise"))}
                        data={data}
                        onInitialized={(figure) => this.setState(figure)}
                        onUpdate={
                            (figure) =>
                                this.setState(figure)
                        }
                        config={plotConfig}>
                    </Plot>}
                    {!loading && data === null && <div style={{
                        display: "flex", alignItems: "center",
                        justifyContent: "center", height: 220
                    }}>
                        <Typography style={{fontSize: 16}} variant={"subtitle1"}>{t("no_data")}</Typography>
                    </div>
                    }

                    {loading && <div style={{
                        display: "flex", flexDirection: "column",
                        alignItems: "center", justifyContent: "center", height: "100%"
                    }}>
                        <CircularProgress/>
                    </div>}
                </Grid>
            </Grid>
        </div>
    )
        ;
};

export default MobileDetail;
