import MapView from "./components/map/MapView";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import './translate/i18n';
import theme from "./theme/bettairTheme";
import React from "react";
import {SnackbarProvider} from "notistack";
import {StylesProvider, ThemeProvider} from "@mui/styles";
import {DialogMessageProvider} from "./hooks/useMessageDialog";

function App() {
  return (
      <StylesProvider injectFirst>
          <SnackbarProvider maxSnack={8}>
          <Router>
              <Switch>
                  <Route path="*">
                      <ThemeProvider theme={theme}>
                          <DialogMessageProvider>
                                <MapView/>
                          </DialogMessageProvider>
                      </ThemeProvider>
                  </Route>
              </Switch>
          </Router>
          </SnackbarProvider>
      </StylesProvider>
  );
}


export default App;
