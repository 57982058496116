import React from 'react';

import {useTranslation} from "react-i18next";
import {Typography} from "@mui/material";

const NotFound = () => {
    const { t } = useTranslation();
    return (
        <div style={{display:"flex", alignItems:"center", justifyContent:"center", width:"100%", height:"100vh"}}>
            <Typography variant={"h1"}>{t("not_found")}</Typography>
        </div>
    );
};

export default NotFound;
