import * as Sentry from "@sentry/react";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';


Sentry.init({
    dsn: `https://${process.env.REACT_APP_SENTRY_KEY}@sentry.bettair.city/${process.env.REACT_APP_SENTRY_PROJECT}`,
    tracesSampleRate: 1.0,
    environment: `${process.env.REACT_APP_ENVIRONMENT}`,
    release: `public-panel@${process.env.REACT_APP_VERSION}`,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [new Sentry.Replay()]
});

ReactDOM.render(
    <App/>
    , document.getElementById('root')
);





