import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './i18n/en.json';
import es from './i18n/es.json';
import el from './i18n/el.json';
import it from './i18n/it.json';
import fr from './i18n/fr.json';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        supportedLngs: ['en', 'es', 'el', 'it', 'fr'],
        detection: {order: ["path", "navigator"]},
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: { en, es, el, it, fr }
    });

export default i18n;
