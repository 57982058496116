import React from 'react';

import {getVariableName} from "../../../utils/pollutantNames";
import {useTranslation} from "react-i18next";
import {Box, Fade, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    toggleButtonContent: {
        maxWidth: 80,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    }
});

const PollutantToggleButtons = ({pollutantList, pollutantCallback, selectedPollutant}) => {
    const {t} = useTranslation()
    const classes = useStyles();

    const handleAlignment = (event, newAlignment) => {
        if (newAlignment !== null) {
            pollutantCallback(newAlignment)
        }
    };

    return (
        <Fade in={true} timeout={900}>
            <ToggleButtonGroup
                value={selectedPollutant}
                exclusive
                onChange={handleAlignment}
            >
                {pollutantList.map(pollutant => {
                    const label = pollutant === "noise" ? t("ambient_noise") : getVariableName(pollutant);
                    return <ToggleButton
                        data-testid={"toggle-" + pollutant}
                        key={pollutant}
                        value={pollutant}
                        title={label}>
                        <Box className={classes.toggleButtonContent}>
                            {label}
                        </Box>
                    </ToggleButton>
                })}
            </ToggleButtonGroup>
        </Fade>
    );
};

export default PollutantToggleButtons;
