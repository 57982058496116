import {createContext, useCallback, useContext, useReducer} from "react";
import {generateId} from "../components/utils/idGenerator";
import MessageDialog from "../components/common/MessageDialog";

const addDialog = (state, payload) => {
    return [...state, payload];
};

const removeDialog = (state, payload) => {
    return state.filter(dialog => dialog.id !== payload);
};

const dialogsReducer = (state, action) => {
    switch (action.type) {
        case "set":
            return [...action.payload];
        case "reset":
            return [];
        case "add":
            return addDialog(state, action.payload);
        case "remove":
            return removeDialog(state, action.payload);
        default:
            return state;
    }
};

const DialogContext = createContext([]);

export const DialogMessageProvider = ({children}) => {
    const [dialogs, dialogDispatch] = useReducer(dialogsReducer, []);

    return (
        <DialogContext.Provider value={{dialogs, dialogDispatch}}>
            {children}
            {dialogs[0] && <MessageDialog key={dialogs[0].id} {...dialogs[0]} />}
        </DialogContext.Provider>
    );
};

export const useMessageDialog = () => {
    const {dialogDispatch} = useContext(DialogContext);

    const removeDialog = useCallback((id) => {
        dialogDispatch({type: "remove", payload: id});
    }, [dialogDispatch]);

    const addDialog = useCallback(({id = generateId(), title, message, confirmText, onClose}) => {
        const onCloseDialog = () => {
            removeDialog(id);
            onClose();
        };
        dialogDispatch({type: "add", payload: {id, title, message, confirmText, onClose: onCloseDialog}});
    }, [dialogDispatch, removeDialog]);

    const showDialog = useCallback(({title, message, confirmText}) => new Promise((resolve) => {
        const id = generateId();
        const onClose = () => resolve();
        addDialog({id, title, message, confirmText, onClose});
    }), [addDialog]);

    return {addDialog, removeDialog, showDialog};
};