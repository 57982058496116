import React, {useEffect, useState} from 'react';
import MobileStationPopUp from "./mobile/MobileStationPopUp";
import DesktopStationPopUp from "./desktop/DesktopStationPopUp";
import {ClickAwayListener} from "@mui/material";


const StationPopUp = ({isMobile, station, units, closePopUp, onHistorySelected, zoom}) => {

    const [{visible}, updateState] = useState({visible: false})
    useEffect(() => {
        let openIn = zoom > 14.5 ? 100 : 600 * (10 / (zoom) * (10 / (zoom)))
        if (openIn > 2000) {
            openIn = 2000
        }
        setTimeout(() => {
            updateState(state => ({visible: true}))
        }, openIn)
    }, [zoom])

    return (
        <>{visible && <ClickAwayListener onClickAway={closePopUp}>
            {isMobile ? <MobileStationPopUp onHistorySelected={onHistorySelected}
                                            station={station}
                                            units={units}/> :
                <DesktopStationPopUp onHistorySelected={onHistorySelected}
                                     station={station}
                                     units={units}
                />}
        </ClickAwayListener>}
        </>
    );
};

export default StationPopUp;
