import { createTheme } from '@mui/material/styles';


const theme = createTheme({
    palette: {
        primary: {
            main: '#3333FF',
        },
        secondary: {
            main: '#0D0E41',
        },
    },
});

export default theme;
