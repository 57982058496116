export const

    NO_DATA_COLOR       = "#969696",
    DARK_GRAY           = "#444444",
    GRAY                = "#AAAAAA",

    TEXT_COLOR          = "#484848",
    TEXT_COLOR_CONTRAST = "#FFFFFF",

    SELECTED_COLOR      = "#FFFFFF";
