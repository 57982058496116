import React from 'react';
import {Stack, ToggleButton, ToggleButtonGroup} from "@mui/material";
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import MapIcon from '@mui/icons-material/Map';

export const mapOptions = {
    MAP: "map",
    SATELLITE: "satellite"
};

const SELECTED_COLOR = "rgba(83,124,178,0.57)";
const DEFAULT_COLOR = "rgba(255,255,255,0.57)";
const ICON_SIZE = 40;
const size = {width: `${ICON_SIZE}px`, height:  `${ICON_SIZE}px`};

const MapSelector = ({map , handleMapChange}) => {

    return (
        <Stack direction="row" spacing={4} style={{ position: 'absolute', bottom: 60, left: 20 }}>
            <ToggleButtonGroup
                value={map}
                exclusive
                onChange={handleMapChange}
                aria-label="text alignment"
            >
                <ToggleButton
                    style={{backgroundColor: map === mapOptions.MAP ? SELECTED_COLOR : DEFAULT_COLOR, ...size}}
                    value={mapOptions.MAP} aria-label="centered">
                    <MapIcon />
                </ToggleButton>
                <ToggleButton
                    style={{backgroundColor: map === mapOptions.SATELLITE ? SELECTED_COLOR : DEFAULT_COLOR, ...size}}
                    value={mapOptions.SATELLITE} aria-label="centered">
                    <SatelliteAltIcon />
                </ToggleButton>
            </ToggleButtonGroup>
        </Stack>);
};

export default MapSelector;
