import React, {useEffect, useState} from 'react';
import RemoveIcon from '@mui/icons-material/Remove';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";
import {Fade, Grid, IconButton, Typography} from "@mui/material";
import AirQualityIndexLegend from "./AirQualityIndexLegend";
import DisclaimerButton from "../../station_pop_up/DisclaimerButton";


const useStyles = makeStyles({
    infoButton: {
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        background:"white",
        borderRadius:"50%",
        width:18,
        height:18,
        padding:16,
        position:"absolute",
        bottom: 32,
        right:32,
        zIndex:1999,
        boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;"
    },

    box: {
        background:"white",
        borderRadius:6,
        maxWidth:390,
        padding:16,
        position:"absolute",
        bottom: 32,
        right:32,
        zIndex:1999,
        boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;"

    },
    mainTittle:{
        color:"#696969",
        fontWeight:"bold !important",
        marginBottom:"8px !important"
    }
});

const DesktopLegend = ({data, openDetail}) => {

    const { t } = useTranslation();

    useEffect(()=>{
        updateState(state=>({...state,showLegend:!openDetail}))
    },[openDetail])

    const [{showLegend},updateState] = useState({showLegend:true})

    const handleMinimize = ()=>{
        updateState({showLegend:false});
    }

    const handleMaximize = ()=>{
        updateState({showLegend:true});
    }

    const classes = useStyles();

    return (<>
                {showLegend &&
                    <Fade in={true} timeout={400}>
                <Grid  container  direction="column" className={classes.box} >
                    <Grid item container xs={12} justifyContent={"space-between"} alignItems={"center"} >
                        <Grid item>
                            <Typography variant={"h6"}
                                        className={classes.mainTittle}>{t(`legend.${data.indexType}`)}</Typography>
                        </Grid>
                        <Grid item>
                            <IconButton aria-label="minimize" onClick={handleMinimize}>
                                <RemoveIcon fontSize="small" />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <AirQualityIndexLegend data = {data}/>
                    <DisclaimerButton style={{marginTop: 8}}/>
                </Grid>
                    </Fade>
                }
                {!showLegend &&
                <Fade in={true} timeout={400}>
                <div className={classes.infoButton}>
                    <IconButton onClick={handleMaximize}>
                        <InfoOutlinedIcon  />
                    </IconButton>
                </div>
                </Fade>
                }
        </>)
}

export default DesktopLegend;
