import {MODEL_EXTERNAL_PREFIX, stationState, airQualityIndexColors} from "../constants";
import {SELECTED_COLOR, NO_DATA_COLOR} from "../map/legend/colors";
import getIconStyle from "./getIconStyle";
import {getIndexRange, getValueColorPosition} from "./airQualityIndexUtil";

const applyMarkerStyle = (el, { model, index, state }, variable, selected) => {
    const value = index;
    const isExternal = model?.startsWith(MODEL_EXTERNAL_PREFIX);
    const markerSize = 20;
    const iconPadding = isExternal ? 8 : 4;
    const borderRadius = isExternal ? 0 : '100%';

    const subEl = document.createElement('div');
    el.appendChild(subEl);

    el.style.width = `${markerSize}px`;
    el.style.height = `${markerSize}px`;
    el.style.display = 'flex';
    el.style.alignItems = 'center';
    el.style.justifyContent = 'center';

    let backgroundColor = '';
    let backgroundUrl = '';
    let borderColor = '';
    let borderSize = null;
    let textColor = '';
    let opacity = '1';

    if (state === stationState.offline || value === undefined) {
        backgroundColor = NO_DATA_COLOR;
    } else {
        const rangeValues = getIndexRange(variable);
        const colorPosition = getValueColorPosition(value, rangeValues);
        backgroundColor = airQualityIndexColors[variable][colorPosition];
    }
    if (selected) {
        borderColor = SELECTED_COLOR;
        borderSize = 3;
    }

    const iconSize = backgroundColor ? markerSize - iconPadding : markerSize;
    const style = getIconStyle({
        iconSize, backgroundUrl, backgroundColor, borderRadius, borderColor, borderSize, textColor, opacity
    });
    Object.assign(subEl.style, style);

    return el;
};

export default applyMarkerStyle;
